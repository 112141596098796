import React from 'react';
import { List, Segment } from 'semantic-ui-react';
import { FormattedMessage, injectIntl } from 'react-intl';


const Footer = ({ intl }) => {
    const currentYear = new Date().getFullYear();

    return (
        <Segment role="contentinfo" padded id="footer">
            <div className="footerTop">

                <div>
                    <List className="footerList">
                        <div role="listitem" className="item footerName">
                            <p className="item">
                                <FormattedMessage
                                    id="Footer-Name"
                                    defaultMessage="Herrmann AG"
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item">
                            <p className="item">
                                <FormattedMessage
                                    id="Footer-Adress"
                                    defaultMessage="Brennerstr. 7"
                                />
                            </p>
                        </div>
                        <div role="listitem" className="item">
                            <p className="item">
                                <FormattedMessage
                                    id='Footer-Plz-Ort'
                                    defaultMessage="3550, Langnau i.E."
                                />
                            </p>
                        </div>
                    </List>
                </div>

                <div>
                    <List className="footerList">
                        <div role="listitem" className="item">
                            <i className="ri-phone-line"></i>
                            <a className="item" href="tel:034 409 40 00">
                                <FormattedMessage
                                    id='Footer-Telefon'
                                    defaultMessage="034 409 40 00"
                                />
                            </a>
                        </div>
                        <div role="listitem" className="item">
                            <i className="ri-mail-line"></i>
                            <a className="item" href="mailto:info@herrmann-druck.ch">
                                <FormattedMessage
                                    id='Footer-Email'
                                    defaultMessage="info@herrmann-druck.ch"
                                />
                            </a>
                        </div>
                    </List>
                </div>
            </div>
            <hr />
            <div className='footerBottom'>
                <div>
                    <List className="footerList">
                        <div role="listitem" className="item">
                            <a className="item" href="./impressum">
                                <FormattedMessage
                                    id='Footer-Imprint'
                                    defaultMessage="Impressum"
                                />
                            </a>
                        </div>
                        <div role="listitem" className="item">
                            <a className="item" href="./datenschutz">
                                <FormattedMessage
                                    id='Footer-Privacy'
                                    defaultMessage="Datenschutzerklärung"
                                />
                            </a>
                        </div>
                        <div role="listitem" className="item">
                            <i className="ri-copyright-line"></i>
                            {currentYear}&nbsp;
                            <FormattedMessage
                                id='Footer-Copyright'
                                defaultMessage="Herrmann AG"
                            />
                        </div>
                    </List>
                </div>
            </div>
        </Segment>
    );
};

export default injectIntl(Footer);